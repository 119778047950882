var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row offers mt-2"
  }, [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('div', {
    staticClass: "card"
  }, [_c('h4', {
    staticClass: "h4 title text-center pt-1 mt-1"
  }, [_vm._v(" Monitoring SO (Sales Order) ")]), _c('div', {
    staticClass: "card-body"
  }, [_c('b-alert', {
    attrs: {
      "variant": "warning",
      "show": "",
      "dismissible": ""
    }
  }, [_vm._v(" This is Page Deprecated Move to so365 ")]), _c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-sm-2",
    attrs: {
      "for": ""
    }
  }, [_vm._v(" Search Book "), _c('br')]), _c('div', {
    staticClass: "col-sm-7"
  }, [_c('input', {
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "name": "searcg",
      "id": ""
    },
    on: {
      "input": _vm.searchSo
    }
  }), _vm.typing ? _c('span', [_c('em', [_vm._v(" Mencari .. ")])]) : _vm._e()]), _c('div', {
    staticClass: "col-md-auto"
  }, [_c('button', {
    staticClass: "btn btn-sm btn-info",
    on: {
      "click": function ($event) {
        return _vm.fetchSalesOrder();
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-refresh"
  })])])]), _c('b-table', {
    attrs: {
      "sticky-header": "700px",
      "items": _vm.items,
      "fields": _vm.fields,
      "busy": _vm.isLoading,
      "responsive": "sm",
      "hover": "",
      "striped": ""
    },
    scopedSlots: _vm._u([{
      key: "cell(No)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s((_vm.currentPage - 1) * _vm.perPage + (row.index + 1)) + " ")];
      }
    }, {
      key: "cell(success)",
      fn: function (row) {
        return [_c('div', [row.item.success == true ? _c('span', {
          staticClass: "badge badge-success"
        }, [_vm._v(" Success ")]) : _c('span', {
          staticClass: "badge badge-danger"
        }, [_vm._v(" Failed ")])])];
      }
    }, {
      key: "cell(Qty)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(row.item.core_so_detail ? row.item.core_so_detail.length : 0) + " ")];
      }
    }, {
      key: "cell(Amount)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(_vm.formatPrice(row.item.amount, "IDR")) + " ")];
      }
    }, {
      key: "cell(detail)",
      fn: function (row) {
        return [_c('b-button', {
          staticClass: "mr-2 btn-success",
          attrs: {
            "size": "sm"
          },
          on: {
            "click": row.toggleDetails
          }
        }, [_vm._v(" " + _vm._s(row.detailsShowing ? 'Hide' : 'Show') + " ")])];
      }
    }, {
      key: "row-details",
      fn: function (row) {
        return [_c('div', [_c('table', [_c('thead', [_c('tr', [_c('th', [_vm._v("Orderline key")]), _c('th', [_vm._v("ID AX")]), _c('th', [_vm._v("sales Price")])])]), _vm._l(row.item.core_so_detail, function (line) {
          return _c('tbody', {
            key: line.id
          }, [_c('tr', [_c('td', [_vm._v(_vm._s(line.id))]), _c('td', [_vm._v(_vm._s(line.itemId))]), _c('td', [_vm._v(" " + _vm._s(_vm.formatPrice(line.salesPrice, "IDR")))])])]);
        })], 2)])];
      }
    }])
  })], 1), _c('b-row', {
    staticClass: "justify-content-start ml-1"
  }, [_c('b-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('b-pagination', {
    attrs: {
      "total-rows": _vm.totalRows,
      "per-page": _vm.perPage,
      "aria-controls": "my-table"
    },
    model: {
      value: _vm.currentPage,
      callback: function ($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1)], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }