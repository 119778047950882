<template>
  <div class="row offers mt-2">
    <b-col lg="12">
      <div class="card">
       
        <h4 class="h4 title text-center pt-1 mt-1"> Monitoring SO (Sales Order) </h4>
        <div class="card-body">
          <b-alert variant="warning" show dismissible>
            This is Page Deprecated Move to so365
          </b-alert>
          <div class="form-group row">
            <label for="" class="col-sm-2"> Search Book <br /> </label>
            <div class="col-sm-7">
              <input type="text" name="searcg" id="" @input="searchSo" class="form-control">
              <span v-if="typing"> <em> Mencari .. </em> </span>
            </div>
            <div class="col-md-auto">
              <button class="btn btn-sm btn-info" @click="fetchSalesOrder()">
                <i class="fa fa-refresh"> </i>
              </button>
            </div>
          </div>
          <b-table
            sticky-header="700px"
            :items="items"
            :fields="fields"
            :busy="isLoading"
            responsive="sm"
            hover
            striped
          >
            <template #cell(No)="row">
              {{ (currentPage - 1) * perPage + (row.index + 1)}}
            </template>
            <template #cell(success)="row">
              <div>
                <span v-if="row.item.success == true" class="badge badge-success">
                  Success
                </span>
                <span v-else class="badge badge-danger">
                  Failed
                </span>
              </div>
            </template>
            <template #cell(Qty)="row">
              {{ row.item.core_so_detail ? row.item.core_so_detail.length : 0 }}
            </template>
            <template #cell(Amount)="row">
              {{ formatPrice(row.item.amount,"IDR") }}
            </template>
            <template #cell(detail)="row">
              <b-button size="sm" @click="row.toggleDetails" class="mr-2 btn-success">
                {{ row.detailsShowing ? 'Hide' : 'Show'}} 
              </b-button>
            </template>
            <template #row-details="row">
              <div>
                <table>
                  <thead>
                    <tr>
                      <th>Orderline key</th>
                      <th>ID AX</th>
                      <th>sales Price</th>
                    </tr>
                  </thead>
                  <tbody v-for="line in row.item.core_so_detail" :key="line.id">
                    <tr>
                      <td>{{line.id}}</td>
                      <td>{{ line.itemId }}</td>
                      <td> {{ formatPrice(line.salesPrice,"IDR") }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </template>
          </b-table>
        </div>
        <b-row class="justify-content-start ml-1">
          <b-col lg="3">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              aria-controls="my-table"
            />
          </b-col>
        </b-row>
      </div>
    </b-col>
  </div>
</template>

<script>
import moment from "moment";
import { mapState, mapGetters, mapActions } from "vuex";
export default {
  name: "MonitoringItemUpload",
  data() {
    return {
      currentPage: 1,
      perPage: 100,
      filter: {},
      isLoadingSearch: false,
      items_search: [],
      fields: [
        { key:'No', label: 'No'},
        {
          key:'ticketNumber',
          label: 'ticketNumber',
          thStyle: { width: "20%" },
          tdStyle: { width: "20%" }
        },
				{
          key:'custAccount',
          label: 'custAccount'
        },
        {
					key:'Qty',
					label: 'Sale Line',
				},
        {
          key:'Amount',
          label: 'Amount',
          style:'text-align:center'
        },
        {
					key:'success',
					label: 'Status',
				},
        {
          key:'message',
          label: 'Info'
        },
        { key:'detail', label: 'Detail'},
        { key:'error', label: 'Error'},
        {
          key:'receiptDateRequested',
          label: 'Created',
          formatter:function(value) {
            return moment(value).format('YYYY MMM DD HH:mm:ss')}
        },
      ],
      typing:null,
      message: null,
    };
  },
  computed: {
    ...mapGetters("So", { items: "fetchData" }),
    ...mapState({
      isLoading: state => state.So.isLoading,
      isError: state => state.So.isError,
      totalRows: state => state.So.totalRows,
      items: state => state.So.items,
    })
  },
  mounted() {
    this.fetchSalesOrder();
  },
  watch: {
    currentPage: function() {
      this.fetchSalesOrder();
    },
    $route: {
      immediate: true,
      handler(to) {
        document.title = to.meta.title || 'Sales Order (365) | Content Management System Ebooks Gramedia.com';
      }
    },
  },
  methods: {
    ...mapActions("So", ["fetchData"]),
    fetchSalesOrder() {
      let payload = {
        page: this.currentPage,
        limit: this.perPage
      };

      this.fetchData(payload);
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    formatDate(tgl) {
      return moment(tgl).format("DD MMM YY hh:mm");
    },
    asyncFind() {},
    customLabel({ name }) {
      if (name) {
        return name;
      } else {
        return "Type Here";
      }
    },
    formatPrice(value, currency) {
			if (value) {
				let formatter = null;
				if (currency == "USD") {
					formatter = new Intl.NumberFormat("en-US", {
						style: "currency",
						currency: currency,
					});
				} else {
					formatter = new Intl.NumberFormat("en-ID", {
						style: "currency",
						currency: currency,
					});
				}

				return formatter.format(value);
			}

			return "0";
		},
    searchSo(event){
      this.typing = 'typing...';
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
          this.typing = null;
          this.message = event.target.value;
          console.log(this.message)
          if (this.message.length > 0) {
            this.fetchData({
              q: event.target.value,
              page: 1,
              limit: this.perPage,
            });
          } else {
            this.fetchSalesOrder();
          }
        }, 600);
    },
  }
};
</script>

<style scoped>
</style>
